import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardText,
  ButtonGroup,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Collapse,
  Table,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Datetime from 'react-datetime';
import _ from 'lodash';
import * as Feather from 'react-feather';
import MUIDataTable from 'mui-datatables';
import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import Cookies from 'js-cookie';
import CandidateInfo from './CandidateInfo';
import { Loader } from '../../components';
import { levelOptions, profileHeader } from '../../components/helpers/reportHelper';
import { CSVLink, CSVDownload } from 'react-csv';
import qs from 'qs';

export default function GroupInterview(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <GroupInterviewSchedules rootPath={url} />
        </Route>
        <Route path={`${path}/info/:id`}>
          <CandidateInfo rootPath={url} rootName={'Group Interview'} from={'group'} />
        </Route>
        <Route path={`${path}/:id`}>
          <InterviewCandidates rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

const statusMap = {
  '': 'Not Selected',
  notrecommended: 'Not Recommended',
  waitinglist: 'Waiting List',
  recommended: 'Recommended',
  absent: 'Absent',
};

function GroupInterviewSchedules({ rootPath }) {
  const localizer = momentLocalizer(moment);
  const history = useHistory();
  const role = Cookies.get('role');
  const scope = role.split(',');

  const groupInterviewSetting = window.sessionStorage.getItem('groupInterview');
  const query = qs.parse(groupInterviewSetting, { ignoreQueryPrefix: true });

  const [isLoading, setIsLoading] = useState(false);

  const [schedules, setSchedules] = useState([]);
  const [unschedules, setUnschedules] = useState([]);
  const [calendar, setCalendar] = useState([]);

  const tabs = [
    { label: 'Unscheduled List', value: 'unscheduled' },
    { label: 'Scheduled List', value: 'schedule' },
    { label: 'Scheduled List By Group', value: 'group' },
    { label: 'Calendar', value: 'calendar' },
  ];

  if (scope.includes('admin')) {
    tabs.splice(2, 0, { label: 'To be reviewed by GenHK', value: 'genhk' });
  }

  const [activeTab, setActiveTab] = useState(query?.activeTab || tabs[0].value);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tmr = new Date();
  tmr.setDate(tmr.getDate() + 1);
  tmr.setHours(12, 0, 0, 0);

  const [groupId, setGroupId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [startDatetime, setStartDatetime] = useState(tmr);
  const [endDatetime, setEndDatetime] = useState(tmr);
  const [interviewLink, setInterviewLink] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);
    setUnschedules([]);
    setCalendar([]);
    const candidate = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'group' });
    const groupBy = _.groupBy(candidate, 'groupInterview.groupId');
    const convetCalendar = [];
    for (let key in groupBy) {
      if (key !== 'undefined') {
        convetCalendar.push({
          id: key,
          title: groupBy[key][0].groupInterview.groupName || key,
          start: new Date(groupBy[key][0].groupInterview.startDatetime),
          end: new Date(groupBy[key][0].groupInterview.endDatetime),
        });
      } else {
        setUnschedules([
          ...groupBy[key].map((v) => {
            v.checked = false;
            return v;
          }),
        ]);
      }
    }
    setCalendar(convetCalendar);
    delete groupBy['undefined'];
    setSchedules(groupBy);
    setIsLoading(false);
  };

  const [modal, setModal] = useState(false);
  const modalToggle = () => {
    if (modal) {
      setGroupId('');
      setGroupName('');
      setStartDatetime(tmr);
      setEndDatetime(tmr);
      setInterviewLink('');
      setImportantNotes('');
      setApplications([]);
      fetchApi();
    }
    setModal(!modal);
  };

  const _createGroupInterview = (context, selectedRow) => {
    if (selectedRow.data.map((v) => unschedules[v.dataIndex].id).length > 0) {
      setApplications(selectedRow.data.map((v) => unschedules[v.dataIndex].id));
      modalToggle();
    } else {
      context.setAlert('Please select at least one candidate', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
  };

  const _scheduleToExistingGrp = async (context, candidate, group) => {
    const result = await fetchWithPost('/dashboard/scheduleGroupInterview', {
      groupId: group,
      groupName: schedules[group][0].groupInterview.groupName,
      startDatetime: new Date(schedules[group][0].groupInterview.startDatetime),
      endDatetime: new Date(schedules[group][0].groupInterview.endDatetime),
      applications: [...schedules[group].map((v) => v.id), candidate.id],
      interviewLink: schedules[group][0].groupInterview.interviewLink,
      importantNotes: schedules[group][0].groupInterview.importantNotes,
    });
    if (result) {
      if (result.status === 400) {
        context.setAlert(result.data.message, 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        context.setAlert('Successfully updated', 'success');
        setTimeout(() => context.closeAlert(), 2000);
      }
    }
    fetchApi();
  };

  const _create = async (context) => {
    if (startDatetime && endDatetime && interviewLink) {
      if (startDatetime >= endDatetime) {
        context.setAlert('Start date is greater than end date', 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else if (startDatetime <= today || endDatetime <= today) {
        context.setAlert('Start date or end date cannot be less than today', 'danger');
        setTimeout(() => context.closeAlert(), 2000);
      } else {
        const result = await fetchWithPost('/dashboard/scheduleGroupInterview', {
          groupId,
          groupName,
          startDatetime,
          endDatetime,
          applications,
          interviewLink,
          importantNotes,
        });
        if (result) {
          if (result.status === 400) {
            context.setAlert(result.data.message, 'danger');
            setTimeout(() => context.closeAlert(), 2000);
          } else {
            context.setAlert('Successfully updated', 'success');
            setTimeout(() => context.closeAlert(), 2000);
          }
        }
      }
    } else {
      context.setAlert('Please fill in all the blank', 'danger');
      setTimeout(() => context.closeAlert(), 2000);
    }
    modalToggle();
  };

  const renderPage = (context) => {
    switch (activeTab) {
      case 'schedule':
        return (
          <MUIDataTable
            key={'schedule'}
            data={Object.keys(schedules)
              .map((k) => schedules[k])
              .flat()
              .map((v, i) => [
                i + 1,
                v.groupInterview.groupName || v.groupInterview.groupId,
                `${moment(v.groupInterview.startDatetime).format('YYYY-MM-DD')}  ${moment(
                  v.groupInterview.startDatetime
                ).format('HH:mm')}-${moment(v.groupInterview.endDatetime).format('HH:mm')}`,
                v.candidate && v.candidate.userId,
                `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                  (v.candidate && v.candidate.surname.zh) || ''
                } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
                `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
                v.candidate && v.candidate.education
                  ? v.candidate.education.level === 'Others'
                    ? v.candidate.education.otherLevel || '-'
                    : levelOptions[v.candidate.education.level] || '-'
                  : '-',
                v.candidate?.reviewed || '-',
                `${v.cohort.en}`,
                v.appliedRoles[0] && v.appliedRoles[0].en,
                v.appliedRoles[0] && v.appliedRoles[0].convenor?.givenName?.en,
                v.appliedRoles[1] && v.appliedRoles[1].en,
                v.appliedRoles[1] && v.appliedRoles[1].convenor?.givenName?.en,
                schedules[v.groupInterview.groupId].length,
                v.groupInterview.status !== 'pending' ? 'Sent' : 'Pending',
              ])}
            columns={[
              '#',
              'Group Name',
              {
                name: 'Scheduled Time',
                options: {
                  filter: true,
                  filterType: 'checkbox',
                  filterOptions: {
                    names: ['Today or before', 'Today', 'Today or after'],
                    logic(date, filterVal) {
                      date = moment(date.split(' ')[0]);
                      const show =
                        (filterVal.indexOf('Today or before') >= 0 && moment().isSameOrAfter(date, 'day')) ||
                        (filterVal.indexOf('Today') >= 0 && moment().isSame(date, 'day')) ||
                        (filterVal.indexOf('Today or after') >= 0 && moment().isSameOrBefore(date, 'day'));
                      return !show;
                    },
                  },
                },
              },
              'Candidate Id',
              'Candidate',
              'Email/Phone',
              'Education Level',
              {
                name: 'Reviewed by GenHK',
                options: {
                  filter: true,
                  filterList: ['Pass'],
                },
              },
              {
                name: 'Cohort',
                // options: {
                //   filter: true,
                //   filterList: ['Cohort 3'],
                // },
              },
              'Selected job role 1',
              'Name of convenor',
              'Selected job role 2',
              'Name of convenor',
              'Group Size',
              'Notification Status',
            ]}
            options={{
              print: false,
              download: false,
              selectableRows: 'none',
              onRowClick: (rowData, rowMeta) => {
                history.push(
                  `${rootPath}/${
                    Object.keys(schedules)
                      .map((k) => schedules[k])
                      .flat()[rowMeta.dataIndex].groupInterview.groupId
                  }`
                );
              },
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
              onChangePage: (number) => {
                query[activeTab] = { ...query[activeTab], page: number };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              onChangeRowsPerPage: (number) => {
                query[activeTab] = { ...query[activeTab], rowsPerPage: number };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              onColumnSortChange: (changedColumn, direction) => {
                query[activeTab] = { ...query[activeTab], sortOrder: { name: changedColumn, direction: direction } };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              page: query[activeTab]?.page,
              rowsPerPage: query[activeTab]?.rowsPerPage,
              sortOrder: query[activeTab]?.sortOrder,
            }}
          />
        );
      case 'genhk':
        return (
          <MUIDataTable
            key={'genhk'}
            data={Object.keys(schedules)
              .map((k) => schedules[k])
              .flat()
              .map((v, i) => [
                i + 1,
                v.candidate && v.candidate.userId,
                `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                  (v.candidate && v.candidate.surname.zh) || ''
                } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
                `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
                v.candidate && v.candidate.education
                  ? v.candidate.education.level === 'Others'
                    ? v.candidate.education.otherLevel || '-'
                    : levelOptions[v.candidate.education.level] || '-'
                  : '-',
                v.candidate?.reviewed || '-',
                statusMap[v.groupInterview?.interviewer?.status || ''],
                `${v.cohort.en}`,
                v.appliedRoles[0] && v.appliedRoles[0].en,
                v.appliedRoles[0] && v.appliedRoles[0].convenor?.givenName?.en,
                statusMap[
                  _.find(v.groupInterview.comments, (a) => a?.convenor?.id === v.appliedRoles[0].convenor?.id)
                    ?.status || ''
                ],
                v.appliedRoles[1] && v.appliedRoles[1].en,
                v.appliedRoles[1] && v.appliedRoles[1].convenor?.givenName?.en,
                statusMap[
                  _.find(v.groupInterview.comments, (a) => a?.convenor?.id === v.appliedRoles[1]?.convenor?.id)
                    ?.status || ''
                ],
              ])}
            columns={[
              '#',
              'Candidate Id',
              'Candidate',
              'Email/Phone',
              'Education Level',
              'Reviewed by GenHK',
              {
                name: 'Hiring Status',
                options: {
                  filter: true,
                  filterList: ['Recommended'],
                },
              },
              'Cohort',
              'Selected job role 1',
              'Name of convenor',
              'Hiring status',
              'Selected job role 2',
              'Name of convenor',
              'Hiring status',
            ]}
            options={{
              print: false,
              download: false,
              selectableRows: 'none',
              onRowClick: (rowData, rowMeta) => {
                history.push(
                  `${rootPath}/${
                    Object.keys(schedules)
                      .map((k) => schedules[k])
                      .flat()[rowMeta.dataIndex].groupInterview.groupId
                  }`
                );
              },
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
              onChangePage: (number) => {
                query[activeTab] = { ...query[activeTab], page: number };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              onChangeRowsPerPage: (number) => {
                query[activeTab] = { ...query[activeTab], rowsPerPage: number };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              onColumnSortChange: (changedColumn, direction) => {
                query[activeTab] = { ...query[activeTab], sortOrder: { name: changedColumn, direction: direction } };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              page: query[activeTab]?.page,
              rowsPerPage: query[activeTab]?.rowsPerPage,
              sortOrder: query[activeTab]?.sortOrder,
            }}
          />
        );
      case 'group':
        return (
          <MUIDataTable
            data={_.map(schedules, (v, i) => [
              v[0].groupInterview.groupName || v[0].groupInterview.groupId,
              `${moment(v[0].groupInterview.startDatetime).format('YYYY-MM-DD')}  ${moment(
                v[0].groupInterview.startDatetime
              ).format('HH:mm')}-${moment(v[0].groupInterview.endDatetime).format('HH:mm')}`,
              _.uniqWith(v.map((v) => v.appliedRoles.map((v) => v.en)).flat(), _.isEqual).toString(),
              v.length,
              v[0].groupInterview.status !== 'pending' ? 'Sent' : 'Pending',
              v[0].groupInterview.groupId,
            ]).reverse()}
            columns={['Group Name', 'Scheduled Time', 'Roles', 'Group Size', 'Notification Status']}
            options={{
              print: false,
              download: false,
              selectableRows: 'none',
              onRowClick: (rowData, rowMeta) => {
                history.push(
                  `${rootPath}/${
                    _.map(schedules, (v, i) => {
                      return { key: i, groupId: v[0].groupInterview.groupId };
                    }).reverse()[rowMeta.dataIndex].groupId
                  }`
                );
              },
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
              onChangePage: (number) => {
                query[activeTab] = { ...query[activeTab], page: number };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              onChangeRowsPerPage: (number) => {
                query[activeTab] = { ...query[activeTab], rowsPerPage: number };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              onColumnSortChange: (changedColumn, direction) => {
                query[activeTab] = { ...query[activeTab], sortOrder: { name: changedColumn, direction: direction } };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              page: query[activeTab]?.page,
              rowsPerPage: query[activeTab]?.rowsPerPage,
              sortOrder: query[activeTab]?.sortOrder,
            }}
          />
        );
      case 'calendar':
        return (
          <Card>
            <CardBody>
              <Calendar
                localizer={localizer}
                events={calendar}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                onSelectEvent={(event) => history.push(`${rootPath}/${event.id}`)}
                onSelectSlot={(e) => console.log({ e })}
              />
            </CardBody>
          </Card>
        );
      default:
        const unscheduleCol = [
          '#',
          'Candidate Id',
          {
            name: 'Candidate',
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
                return <a href={`${rootPath}/info/${unschedules[rowIndex].candidate.id}`}>{value}</a>;
              },
              filter: false,
            },
          },
          'Email/Phone',
          'Education Level',
          {
            name: 'Reviewed by GenHK',
            options: {
              filter: true,
              filterList: ['Pass'],
            },
          },
          {
            name: 'Cohort',
            // options: {
            //   filter: true,
            //   filterList: ['Cohort 3'],
            // },
          },
          'Selected job role 1',
          'Name of convenor',
          'Selected job role 2',
          'Name of convenor',
          {
            name: 'Schedule to existing group',
            options: {
              filter: false,
              download: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                const rowIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
                return (
                  <Input
                    type="select"
                    name="select"
                    value={''}
                    onChange={(a) => _scheduleToExistingGrp(context, unschedules[rowIndex], a.target.value)}
                  >
                    <option value={''}>{'Please select existing group'}</option>
                    {Object.keys(schedules)
                      .map((k, i) => (
                        <option key={i} value={k}>
                          {schedules[k][0].groupInterview.groupName || k.toString()}
                        </option>
                      ))
                      .reverse()}
                  </Input>
                );
              },
            },
          },
        ];
        // if (scope.includes('admin')) {
        //   unscheduleCol.push({
        //     name: 'Schedule to existing group',
        //     options: {
        //       filter: false,
        //       download: false,
        //       customBodyRender: (value, tableMeta, updateValue) => {
        //         return (
        //           <Input
        //             type="select"
        //             name="select"
        //             value={''}
        //             onChange={(a) => _scheduleToExistingGrp(context, unschedules[tableMeta.rowIndex], a.target.value)}
        //           >
        //             <option value={''}>{'Please select existing group'}</option>
        //             {Object.keys(schedules).map((k, i) => (
        //               <option key={i} value={k}>
        //                 {schedules[k][0].groupInterview.groupName || k.toString()}
        //               </option>
        //             ))}
        //           </Input>
        //         );
        //       },
        //     },
        //   });
        // }
        return (
          <MUIDataTable
            key={'unschedule'}
            data={unschedules.map((v, i) => [
              i + 1,
              v.candidate.userId,
              `${v.candidate && v.candidate.surname.en} ${v.candidate && v.candidate.givenName.en}\n${
                (v.candidate && v.candidate.surname.zh) || ''
              } ${(v.candidate && v.candidate.givenName.zh) || ''}`,
              `${v.candidate && v.candidate.email}/${v.candidate && v.candidate.phoneNo}`,
              v.candidate && v.candidate.education
                ? v.candidate.education.level === 'Others'
                  ? v.candidate.education.otherLevel || '-'
                  : levelOptions[v.candidate.education.level] || '-'
                : '-',
              v.candidate?.reviewed || '-',
              v.cohort.en,
              v.appliedRoles[0] && v.appliedRoles[0].en,
              v.appliedRoles[0] && v.appliedRoles[0].convenor?.givenName?.en,
              v.appliedRoles[1] && v.appliedRoles[1].en,
              v.appliedRoles[1] && v.appliedRoles[1].convenor?.givenName?.en,
            ])}
            columns={unscheduleCol}
            options={{
              print: false,
              download: false,
              selectableRows: 'multiple',
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <Button color="primary" className="mr-4" onClick={() => _createGroupInterview(context, selectedRows)}>
                  Schedule group interview
                </Button>
              ),
              customSearch: (searchQuery, currentRow, columns) => {
                const splitText = searchQuery.split(',');
                let isFound = false;
                currentRow.forEach((col) => {
                  splitText.forEach((v) => {
                    if ((col || '').toString().indexOf(v) >= 0) {
                      isFound = true;
                    }
                  });
                });
                return isFound;
              },
              textLabels: {
                body: {
                  noMatch: isLoading ? <Loader type={'spin'} /> : 'Sorry, no matching records found',
                },
              },
              onChangePage: (number) => {
                query[activeTab] = { ...query[activeTab], page: number };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              onChangeRowsPerPage: (number) => {
                query[activeTab] = { ...query[activeTab], rowsPerPage: number };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              onColumnSortChange: (changedColumn, direction) => {
                query[activeTab] = { ...query[activeTab], sortOrder: { name: changedColumn, direction: direction } };
                window.sessionStorage.setItem('groupInterview', qs.stringify(query));
              },
              page: query[activeTab]?.page,
              rowsPerPage: query[activeTab]?.rowsPerPage,
              sortOrder: query[activeTab]?.sortOrder,
            }}
          />
        );
    }
  };

  const profileConvert = (v) => {
    return {
      id: v?.candidate?.userId || '',
      cohort: v.cohort && v.cohort.en,
      givenNameEn: v?.candidate?.givenName?.en || '',
      surnameEn: v?.candidate?.surname?.en || '',
      givenNameZh: v.candidate.givenName.zh || '',
      surnameZh: v.candidate.surname.zh || '',
      preferredName: v?.candidate?.preferredName || '',
      gender: v?.candidate?.gender,
      phoneNo: v?.candidate?.phoneNo,
      email: v?.candidate?.email,
      resume: v?.candidate?.resume,
      remark:
        v?.candidate?.remark
          ?.replace(',', '')
          ?.replace('"', '')
          ?.replace(/(?:\\[rn])+/g, '') || '',
      portfolio: v?.candidate?.portfolio,
      createdAt: moment(v?.candidate?.createdAt).format('YYYY-MM-DD'),
      reviewed: v?.candidate?.reviewed,
      toCohort3: v?.candidate?.toCohort3,
      cohort3Group: v?.candidate?.cohort3Group,
      cohort3GroupFinal: v?.candidate?.cohort3GroupFinal,
      jobOffer1:
        v.offer &&
        v.offer[0] &&
        `${v.offer[0].finalInterview.position?.stream?.en}/${v.offer[0].finalInterview.position?.role?.en}`,
      jobEmployer1: v.offer && v.offer[0] && `${v.offer[0].finalInterview.employer.givenName.en}`,
      jobStatus1: v.offer && v.offer[0] && `${_.startCase(v.offer[0].status)}ed`,
      jobOffer2:
        v.offer &&
        v.offer[1] &&
        `${v.offer[1].finalInterview.position.role.parentId.en}/${v.offer[1].finalInterview.position.role.en}`,
      jobEmployer2: v.offer && v.offer[1] && `${v.offer[1].finalInterview.employer.givenName.en}`,
      jobStatus2: v.offer && v.offer[1] && `${_.startCase(v.offer[1].status)}ed`,
      jobOffer3:
        v.offer &&
        v.offer[2] &&
        `${v.offer[2].finalInterview.position.role.parentId.en}/${v.offer[2].finalInterview.position.role.en}`,
      jobEmployer3: v.offer && v.offer[2] && `${v.offer[2].finalInterview.employer.givenName.en}`,
      jobStatus3: v.offer && v.offer[2] && `${_.startCase(v.offer[2].status)}ed`,
      jobOffer4:
        v.offer &&
        v.offer[3] &&
        `${v.offer[3].finalInterview.position.role.parentId.en}/${v.offer[3].finalInterview.position.role.en}`,
      jobEmployer4: v.offer && v.offer[3] && `${v.offer[3].finalInterview.employer.givenName.en}`,
      jobStatus4: v.offer && v.offer[3] && `${_.startCase(v.offer[3].status)}ed`,
      roleConvenor1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].convenor.givenName.en,
      roleCohort1: v.appliedRoles && v.appliedRoles[0] && v.appliedRoles[0].cohort.toString(),
      role1: v.appliedRoles && v.appliedRoles[0] && `${v.appliedRoles[0].parentId.en} - ${v.appliedRoles[0].en}`,
      roleConvenor2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[1].convenor.givenName.en,
      roleCohort2: v.appliedRoles && v.appliedRoles[1] && v.appliedRoles[1].cohort.toString(),
      level: v?.candidate?.education
        ? v?.candidate?.education.level === 'Others'
          ? v?.candidate?.education.otherLevel || ''
          : levelOptions[v?.candidate?.education.level] || ''
        : '',
      location: v?.candidate?.education
        ? v?.candidate?.education.otherLocation ||
          (v?.candidate?.education.location && v?.candidate?.education.location.en)
        : '',
      institution: v?.candidate?.education
        ? v?.candidate?.education.otherInstitution ||
          (v?.candidate?.education.institution && v?.candidate?.education.institution.en)
        : '',
      programme: v?.candidate?.education && v?.candidate?.education.programmeName,
      areaOfStudy: v?.candidate?.education
        ? v?.candidate?.education.otherAreaOfStudy ||
          (v?.candidate?.education.areaOfStudy && v?.candidate?.education.areaOfStudy.en)
        : '',
      studyDuration:
        v?.candidate?.education &&
        `${v?.candidate?.education.startYear}/${v?.candidate?.education.startMonth} - ${
          _.isInteger(v?.candidate?.education.gradYear)
            ? `${v?.candidate?.education.gradYear}/${v?.candidate?.education.gradMonth}`
            : `${v?.candidate?.education.gradYear}`
        }`,
      paidExpCount: v.vask && v.vask.paidExp && v.vask.paidExp.length,
      employmentCount:
        v.vask && v.vask.paidExp && v.vask.paidExp.filter((a) => a.type && a.type.en === 'Employment').length,
      entrepreneurCount:
        v.vask && v.vask.paidExp && v.vask.paidExp.filter((a) => a.type && a.type.en === 'Entrepreneur').length,
      otherCount:
        v.vask &&
        v.vask.paidExp &&
        v.vask.paidExp.filter((a) => a.type && a.type.en !== 'Employment' && a.type.en !== 'Entrepreneur').length,
      pe1Type: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].type && v.vask.paidExp[0].type.en,
      pe1Organization: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].organization,
      pe1Role: v.vask && v.vask.paidExp[0] && v.vask.paidExp[0].role,
      pe1FromTo:
        v.vask &&
        v.vask.paidExp[0] &&
        `${moment(v.vask.paidExp[0].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[0].to).format('YYYY-MM-DD')}`,
      pe2Type: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].type && v.vask.paidExp[1].type.en,
      pe2Organization: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].organization,
      pe2Role: v.vask && v.vask.paidExp[1] && v.vask.paidExp[1].role,
      pe2FromTo:
        v.vask &&
        v.vask.paidExp[1] &&
        `${moment(v.vask.paidExp[1].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[1].to).format('YYYY-MM-DD')}`,
      pe3Type: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].type && v.vask.paidExp[2].type.en,
      pe3Organization: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].organization,
      pe3Role: v.vask && v.vask.paidExp[2] && v.vask.paidExp[2].role,
      pe3FromTo:
        v.vask &&
        v.vask.paidExp[2] &&
        `${moment(v.vask.paidExp[2].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[2].to).format('YYYY-MM-DD')}`,
      pe4Type: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].type && v.vask.paidExp[3].type.en,
      pe4Organization: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].organization,
      pe4Role: v.vask && v.vask.paidExp[3] && v.vask.paidExp[3].role,
      pe4FromTo:
        v.vask &&
        v.vask.paidExp[3] &&
        `${moment(v.vask.paidExp[3].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[3].to).format('YYYY-MM-DD')}`,
      pe5Type: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].type && v.vask.paidExp[4].type.en,
      pe5Organization: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].organization,
      pe5Role: v.vask && v.vask.paidExp[4] && v.vask.paidExp[4].role,
      pe5FromTo:
        v.vask &&
        v.vask.paidExp[4] &&
        `${moment(v.vask.paidExp[4].from).format('YYYY-MM-DD')} - ${moment(v.vask.paidExp[4].to).format('YYYY-MM-DD')}`,
      nonPaidExpCount: v.vask && v.vask.unpaidExp && v.vask.unpaidExp.length,
      voluntaryCount:
        v.vask && v.vask.unpaidExp && v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Voluntary').length,
      vocationalCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Vocational and Educational Training / Work Exposure')
          .length,
      seriousCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Serious Leisure or Interest').length,
      domesticCount:
        v.vask &&
        v.vask.unpaidExp &&
        v.vask.unpaidExp.filter((a) => a.type && a.type.en === 'Domestic and/or Neighborhood Provisioning').length,
      npe1Type: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].type && v.vask.unpaidExp[0].type.en,
      npe1Organization: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].organization,
      npe1Role: v.vask && v.vask.unpaidExp[0] && v.vask.unpaidExp[0].role,
      npe1FromTo:
        v.vask &&
        v.vask.unpaidExp[0] &&
        `${moment(v.vask.unpaidExp[0].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[0].to).format(
          'YYYY-MM-DD'
        )}`,
      npe2Type: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].type && v.vask.unpaidExp[1].type.en,
      npe2Organization: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].organization,
      npe2Role: v.vask && v.vask.unpaidExp[1] && v.vask.unpaidExp[1].role,
      npe2FromTo:
        v.vask &&
        v.vask.unpaidExp[1] &&
        `${moment(v.vask.unpaidExp[1].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[1].to).format(
          'YYYY-MM-DD'
        )}`,
      npe3Type: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].type && v.vask.unpaidExp[2].type.en,
      npe3Organization: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].organization,
      npe3Role: v.vask && v.vask.unpaidExp[2] && v.vask.unpaidExp[2].role,
      npe3FromTo:
        v.vask &&
        v.vask.unpaidExp[2] &&
        `${moment(v.vask.unpaidExp[2].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[2].to).format(
          'YYYY-MM-DD'
        )}`,
      npe4Type: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].type && v.vask.unpaidExp[3].type.en,
      npe4Organization: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].organization,
      npe4Role: v.vask && v.vask.unpaidExp[3] && v.vask.unpaidExp[3].role,
      npe4FromTo:
        v.vask &&
        v.vask.unpaidExp[3] &&
        `${moment(v.vask.unpaidExp[3].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[3].to).format(
          'YYYY-MM-DD'
        )}`,
      npe5Type: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].type && v.vask.unpaidExp[4].type.en,
      npe5Organization: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].organization,
      npe5Role: v.vask && v.vask.unpaidExp[4] && v.vask.unpaidExp[4].role,
      npe5FromTo:
        v.vask &&
        v.vask.unpaidExp[4] &&
        `${moment(v.vask.unpaidExp[4].from).format('YYYY-MM-DD')} - ${moment(v.vask.unpaidExp[4].to).format(
          'YYYY-MM-DD'
        )}`,
      V1: v.vask && v.vask.values[0] && v.vask.values[0].en,
      V2: v.vask && v.vask.values[1] && v.vask.values[1].en,
      V3: v.vask && v.vask.values[2] && v.vask.values[2].en,
      A1: v.vask && v.vask.attitudes[0] && v.vask.attitudes[0].en,
      A2: v.vask && v.vask.attitudes[1] && v.vask.attitudes[1].en,
      A3: v.vask && v.vask.attitudes[2] && v.vask.attitudes[2].en,
      S1: v.vask && v.vask.skills[0] && v.vask.skills[0].en,
      S2: v.vask && v.vask.skills[1] && v.vask.skills[1].en,
      S3: v.vask && v.vask.skills[2] && v.vask.skills[2].en,
      K1: v.vask && v.vask.knowledge[0] && v.vask.knowledge[0].en,
      K2: v.vask && v.vask.knowledge[1] && v.vask.knowledge[1].en,
      K3: v.vask && v.vask.knowledge[2] && v.vask.knowledge[2].en,
    };
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Group Interview</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      {/* <div className="text-right mb-2">
        <CSVLink filename={`Full-Profile-Export`} data={unschedules.map(profileConvert)} headers={profileHeader}>
          Export Candidates
        </CSVLink>
      </div> */}
      <Nav tabs>
        {tabs.map((v, i) => (
          <NavItem key={i}>
            <NavLink
              href="#"
              className={`${activeTab === v.value ? 'active' : ''}`}
              onClick={() => {
                window.sessionStorage.setItem('groupInterview', qs.stringify({ ...query, activeTab: v.value }));
                setActiveTab(v.value);
              }}
            >
              {v.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <PageAlertContext.Consumer>
        {(context) => (
          <div>
            {renderPage(context)}
            <Modal centered={true} isOpen={modal} toggle={modalToggle}>
              <ModalHeader toggle={modalToggle}>
                <b>Schedule Group Interview</b>
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label className="mb-2">Group Name</Label>
                  <Input placeholder="Group Name" value={groupName} onChange={(v) => setGroupName(v.target.value)} />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Start time</Label>
                  <Datetime value={startDatetime} onChange={(date) => setStartDatetime(date)} />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">End time</Label>
                  <Datetime value={endDatetime} onChange={(date) => setEndDatetime(date)} />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Interview link</Label>
                  <Input
                    placeholder="Interview link"
                    value={interviewLink}
                    onChange={(v) => setInterviewLink(v.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="mb-2">Important Notes</Label>
                  <Input
                    placeholder="Important Notes"
                    value={importantNotes}
                    onChange={(v) => setImportantNotes(v.target.value)}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => _create(context)}>
                  Schedule
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </PageAlertContext.Consumer>
    </div>
  );
}

function InterviewCandidates({ rootPath }) {
  let { id } = useParams();
  let { url } = useRouteMatch();
  const role = Cookies.get('role');
  const scope = role.split(',');

  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const [groupInfo, setGroupInfo] = useState([]);

  const [groupId, setGroupId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [startDatetime, setStartDatetime] = useState('');
  const [endDatetime, setEndDatetime] = useState('');
  const [interviewLink, setInterviewLink] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const [applications, setApplications] = useState([]);

  const fetchApi = async () => {
    const result = await fetchWithGet('/dashboard/getStageCandidate', { stage: 'group' });
    if (result) {
      const groupBy = _.groupBy(result, 'groupInterview.groupId');
      if (groupBy[id]) {
        setGroupInfo(groupBy[id]);
        setGroupId(groupBy[id][0].groupInterview.groupId);
        setGroupName(groupBy[id][0].groupInterview.groupName);
        setStartDatetime(new Date(groupBy[id][0].groupInterview.startDatetime));
        setEndDatetime(new Date(groupBy[id][0].groupInterview.endDatetime));
        setInterviewLink(groupBy[id][0].groupInterview.interviewLink);
        setImportantNotes(groupBy[id][0].groupInterview.importantNotes);
        setApplications(groupBy[id].map((v) => v.id));
      }
    }
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const _editGroupInterview = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (startDatetime && endDatetime && interviewLink) {
      if (startDatetime >= endDatetime) {
        alertContext.setAlert('Start date is greater than end date', 'danger');
      } else if (startDatetime <= today || endDatetime <= today) {
        alertContext.setAlert('Start date or end date cannot be less than today', 'danger');
      } else {
        const result = await fetchWithPost('/dashboard/scheduleGroupInterview', {
          groupId,
          groupName,
          startDatetime,
          endDatetime,
          applications,
          interviewLink,
          importantNotes,
        });
        if (result) {
          if (result.status === 400) {
            alertContext.setAlert(result.data.message, 'danger');
          } else {
            alertContext.setAlert('Successfully updated', 'success');
          }
        }
        fetchApi();
      }
    } else {
      alertContext.setAlert('Please fill in all the blank', 'danger');
    }
  };

  const _notification = async () => {
    const confirm = await popUpContext.onOpen('Are you confirm to send notification?');
    if (confirm) {
      const result = await fetchWithPost('/dashboard/sendNotification', {
        id: groupId,
        stage: 'group',
      });
      if (result && (result.status === 400 || result.status === 404)) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
      }
      fetchApi();
    }
  };

  const _removeFromGroup = async (applicationId) => {
    const confirm = await popUpContext.onOpen('Are you confirm to remove candidate from this group?');
    if (confirm) {
      const mapApp = applications;
      mapApp.splice(mapApp.indexOf(applicationId), 1);
      const result = await fetchWithPost('/dashboard/scheduleGroupInterview', {
        groupId,
        groupName,
        startDatetime,
        endDatetime,
        applications: mapApp,
        interviewLink,
        importantNotes,
      });
      if (result) {
        if (result.status === 400) {
          alertContext.setAlert(result.data.message, 'danger');
        } else {
          alertContext.setAlert('Successfully updated', 'success');
        }
      }
    }
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Group Interview</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${url}/${id}`}>{groupName || id}</Link>
        </BreadcrumbItem>
      </Breadcrumb>
      {scope.includes('admin') ? (
        <Card>
          <CardHeader>
            <b>Group Interview Information</b>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label className="mb-2">Group Name</Label>
              <Input placeholder="Group Name" value={groupName} onChange={(v) => setGroupName(v.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label className="mb-2">Start time</Label>
              <Datetime value={startDatetime} onChange={(date) => setStartDatetime(date)} />
            </FormGroup>
            <FormGroup>
              <Label className="mb-2">End time</Label>
              <Datetime value={endDatetime} onChange={(date) => setEndDatetime(date)} />
            </FormGroup>
            <FormGroup>
              <Label className="mb-2">Interview link</Label>
              <Input
                placeholder="Interview link"
                value={interviewLink}
                onChange={(v) => setInterviewLink(v.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mb-2">Important Notes</Label>
              <Input
                placeholder="Important Notes"
                value={importantNotes}
                onChange={(v) => setImportantNotes(v.target.value)}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <div className="mb-2 d-flex justify-content-between">
              <Button color="primary" outline onClick={() => _notification()}>
                Send Notification
              </Button>
              <Button color="primary" outline onClick={() => _editGroupInterview()}>
                Edit Schedule
              </Button>
            </div>
          </CardFooter>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <b>Group Interview Information</b>
          </CardHeader>
          <CardBody>
            <CardText>Group Name: {groupName}</CardText>
            <CardText>Group Size: {groupInfo.length}</CardText>
            <CardText>
              Interview Time:{' '}
              {`${moment(startDatetime).format('YYYY-MM-DD')}  ${moment(startDatetime).format('HH:mm')}-${moment(
                endDatetime
              ).format('HH:mm')}`}
            </CardText>
            <CardText>
              Interview Link:{' '}
              <a href={interviewLink} target="_blank" rel="noopener noreferrer">
                {interviewLink}
              </a>
            </CardText>
            <CardText>Important Notes: {importantNotes}</CardText>
          </CardBody>
        </Card>
      )}
      {groupInfo.map((v, i) => {
        return (
          <InterviewCandidate
            rootPath={rootPath}
            key={i}
            candidate={v.candidate}
            interview={v.groupInterview}
            refresh={fetchApi}
            appliedRoles={v.appliedRoles}
            removeFromGroup={() => _removeFromGroup(v.id)}
          />
        );
      })}
    </div>
  );
}

function InterviewCandidate({ rootPath, candidate, interview, refresh, removeFromGroup, appliedRoles }) {
  const history = useHistory();
  const role = Cookies.get('role');
  const scope = role.split(',');

  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const userId = Cookies.get('userId');
  const [interviewNotes, setInterviewNotes] = useState(
    scope.includes('admin')
      ? interview.interviewer.interviewNotes || ''
      : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).interviewNotes || ''
  );
  const [isOpen, setIsOpen] = useState(true);
  const ExpandIcon = isOpen ? Feather.ChevronDown : Feather.ChevronRight;

  const _save = async (status) => {
    const result = await fetchWithPost('/dashboard/updateInterviewStatus', {
      stage: 'group',
      interviewId: interview.id,
      status: status,
      // ||
      // (scope.includes('admin')
      //   ? interview.interviewer.status
      //   : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status || ''),
      interviewNotes: interviewNotes,
    });

    if (result && (result.status === 400 || result.status === 404)) {
      alertContext.setAlert(result.data.message, 'danger');
    } else {
      alertContext.setAlert('Successfully updated', 'success');
    }
    refresh();
  };

  const _notification = async () => {
    const confirm = await popUpContext.onOpen('Are you confirm to send notification?');
    if (confirm) {
      const result = await fetchWithPost('/dashboard/sendNotification', {
        id: interview.id,
        stage: 'inPerson',
      });
      if (result && (result.status === 400 || result.status === 404)) {
        alertContext.setAlert(result.data.message, 'danger');
      } else {
        alertContext.setAlert('Successfully updated', 'success');
      }
    }
  };
  const _getColor = (status) => {
    switch (status) {
      case 'recommended':
        return 'text-success';
      case 'waitinglist':
        return 'text-warning';
      case 'notrecommended':
        return 'text-danger';
      case 'absent':
        return 'text-info';
      default:
        return '';
    }
  };
  const getRecommendation = (status) => {
    switch (status) {
      case 'recommended':
        return (
          <Badge color="primary" pill>
            Recommended
          </Badge>
        );
      case 'waitinglist':
        return (
          <Badge color="warning" pill>
            Waiting List
          </Badge>
        );
      case 'notrecommended':
        return (
          <Badge color="danger" pill>
            Not Recommended
          </Badge>
        );
      case 'absent':
        return (
          <Badge color="info" pill>
            Absent
          </Badge>
        );
      default:
        return <div />;
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row onClick={() => setIsOpen(!isOpen)}>
          <Col xs={11}>
            {`${candidate.givenName.en} ${candidate.surname.en} (${candidate.userId})`}
            {scope.includes('admin') ? (
              <>
                <br />
                {appliedRoles.map((v) => (
                  <div>
                    {v.en}
                    <div>
                      {v.convenor?.givenName?.en}:{' '}
                      {getRecommendation(_.find(interview.comments, (a) => a.convenor.id === v.convenor.id)?.status)}
                    </div>
                  </div>
                ))}
                {/* {interview.comments.map((v) => (
                  <div>
                    {v.convenor?.givenName?.en}: {getRecommendation(v.status)}
                  </div>
                ))} */}
              </>
            ) : (
              <></>
            )}
          </Col>
          <Col xs={1} className="text-right">
            <ExpandIcon className="menu-expand-icon" />
          </Col>
        </Row>
        <Row>
          <Col>
            {scope.includes('admin') ? (
              <ButtonGroup block={true} style={{ width: '100%', marginTop: 20 }}>
                <Button color="danger" onClick={removeFromGroup}>
                  Remove from group
                </Button>
                <Button color="primary" onClick={() => _notification()}>
                  Send notification
                </Button>
              </ButtonGroup>
            ) : (
              <div />
            )}
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Button color="primary" block onClick={() => history.push(`${rootPath}/info/${candidate.id}`)}>
            Full Profile
          </Button>
          <Row className="mt-3">
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  {!_.isEmpty(candidate) ? (
                    <div>
                      <Label>Personal Information</Label>
                      <Table>
                        <tbody>
                          <tr>
                            <td>English Name</td>
                            <td>{`${candidate.givenName.en} ${candidate.surname.en}`}</td>
                          </tr>
                          <tr>
                            <td>Chinese Name</td>
                            <td>{`${candidate.surname.zh || ''}${candidate.givenName.zh || ''}`}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{candidate.email}</td>
                          </tr>
                          <tr>
                            <td>Phone No</td>
                            <td>{candidate.phoneNo}</td>
                          </tr>
                        </tbody>
                      </Table>
                      {scope.includes('admin') ? (
                        <CardBody>
                          {appliedRoles.map((v) => (
                            <div>
                              {v.en}
                              <div>
                                {v.convenor?.givenName?.en}:{' '}
                                {getRecommendation(
                                  _.find(interview.comments, (a) => a.convenor.id === v.convenor.id)?.status
                                )}
                              </div>
                            </div>
                          ))}
                          {/* {interview.comments.map((v) => (
                            <div>
                              {v.convenor?.givenName?.en}: {getRecommendation(v.status)}
                            </div>
                          ))} */}
                        </CardBody>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardBody>
                  {!_.isEmpty(candidate) ? (
                    <div>
                      <Label>Education Background</Label>
                      <Table>
                        <tbody>
                          <tr>
                            <td>Institution</td>
                            <td>
                              {candidate.education?.institution && candidate.education.institution.en}
                              <br />
                              {candidate.education?.otherInstitution || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Programme Name</td>
                            <td>{candidate.education?.programmeName}</td>
                          </tr>
                          <tr>
                            <td>Area of Study</td>
                            <td>
                              {candidate.education?.areaOfStudy && candidate.education.areaOfStudy.en}
                              <br />
                              {candidate.education?.other_areaOfStudy || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Location</td>
                            <td>
                              {candidate.education?.location && candidate.education?.location.en}
                              <br />
                              {candidate.education?.other_location || ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Start Date</td>
                            <td>{`${candidate.education?.startYear || ''}/${
                              candidate.education?.startMonth || ''
                            }`}</td>
                          </tr>
                          <tr>
                            <td>Grad Date</td>
                            <td>
                              {_.isInteger(candidate.education?.gradYear)
                                ? `${candidate.education?.gradYear || ''}/${candidate.education?.gradMonth || ''}`
                                : `${candidate.education?.gradYear || ''}`}
                            </td>
                          </tr>
                          <tr>
                            <td>Resume</td>
                            <td>
                              <Button href={candidate.resume || ''} color="link" target="_blank">
                                Resume
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Collapse>
      <CardBody>
        <Row>
          <Col sm={12} md={6}>
            <FormGroup>
              <Input
                type="textarea"
                name="notes"
                id="notes"
                value={interviewNotes}
                placeholder={'Interview Note'}
                className="mb-2"
                onChange={(e) => setInterviewNotes(e.target.value)}
              />
              <Button
                onClick={() =>
                  _save(
                    scope.includes('admin')
                      ? interview.interviewer.status
                      : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status || ''
                  )
                }
                block
                outline
              >
                Save
              </Button>
            </FormGroup>
          </Col>
          <Col sm={12} md={6}>
            <div>
              <Label>Will you recommend this candidate.</Label>
              <ButtonGroup style={{ width: '100%' }}>
                <Button
                  color="success"
                  outline={
                    !(
                      (scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                      'recommended'
                    )
                  }
                  onClick={() => {
                    if (
                      (scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                      'recommended'
                    ) {
                      _save('');
                    } else {
                      _save('recommended');
                    }
                  }}
                >
                  Recommended
                </Button>
                <Button
                  color="warning"
                  outline={
                    !(
                      (scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                      'waitinglist'
                    )
                  }
                  onClick={() => {
                    if (
                      (scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                      'waitinglist'
                    ) {
                      _save('');
                    } else {
                      _save('waitinglist');
                    }
                  }}
                >
                  Waiting List
                </Button>
                <Button
                  color="danger"
                  outline={
                    !(
                      (scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                      'notrecommended'
                    )
                  }
                  onClick={() => {
                    if (
                      (scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) ===
                      'notrecommended'
                    ) {
                      _save('');
                    } else {
                      _save('notrecommended');
                    }
                  }}
                >
                  Not Recommended
                </Button>
                <Button
                  color="info"
                  outline={
                    !(
                      (scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) === 'absent'
                    )
                  }
                  onClick={() => {
                    if (
                      (scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status) === 'absent'
                    ) {
                      _save('');
                    } else {
                      _save('absent');
                    }
                  }}
                >
                  Absent
                </Button>
              </ButtonGroup>
              <div>
                Status:{' '}
                <b>
                  <span
                    className={_getColor(
                      scope.includes('admin')
                        ? interview.interviewer.status
                        : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status
                    )}
                  >
                    {_.startCase(
                      statusMap[
                        scope.includes('admin')
                          ? interview.interviewer.status
                          : ((interview.comments || []).find((v) => v.convenor.id === userId) || {}).status
                      ]
                    ) || 'Not Selected'}
                  </span>
                </b>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
