import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { fetchWithGet, fetchWithPost, fetchWithDelete } from '../../httpHelper';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import Datetime from 'react-datetime';
import _ from 'lodash';

import PageAlertContext from '../../components/components/PageAlert/PageAlertContext';
import PopUpContext from '../../components/components/PopUp/PopUpContext';
import Cookies from 'js-cookie';

import { Calendar, momentLocalizer } from 'react-big-calendar';

export default function CalendarPage(props) {
  let { path, url } = useRouteMatch();

  return (
    <Router>
      <Switch>
        <Route exact path={path}>
          <CalendarList rootPath={url} />
        </Route>
      </Switch>
    </Router>
  );
}

function CalendarList({ rootPath }) {
  const history = useHistory();
  const role = Cookies.get('role');
  const userId = Cookies.get('userId');
  const localizer = momentLocalizer(moment);

  const alertContext = useContext(PageAlertContext);
  const popUpContext = useContext(PopUpContext);

  const [isLoading, setIsLoading] = useState(false);

  const [calendar, setCalendar] = useState([]);
  const [event, setEvent] = useState({});
  const fixColor = [
    { color: '#0d6efd', label: 'GENHK' }, // for GENHK
    { color: '#00ccaa', label: 'CUHK' },
  ]; // for CUHK
  const [colors, setColors] = useState([]);

  useEffect(() => {
    fetchApi();
  }, []);
  const fetchApi = async () => {
    setIsLoading(true);

    const calendarList = await fetchWithGet('/research/getCalendar');
    if (calendarList) {
      setCalendar(
        calendarList.map((v) => ({
          ...v,
          startDatetime: new Date(v.startDatetime),
          endDatetime: new Date(v.endDatetime),
        }))
      );
    }

    const result = await fetchWithGet('/dashboard/getDashboardUsers');
    if (result) {
      setEvent({ color: _.find(result, (v) => v.id === userId).color });
      setColors([
        ...fixColor,
        ...result
          .filter((v) => v.role === 'convenor' && v.givenName.en !== 'Po Leung Kuk')
          .map((v) => ({ color: v.color, label: v.givenName.en })),
      ]);
    }

    setIsLoading(false);
  };

  const _create = async () => {
    const confirm = await popUpContext.onOpen(
      `Are you confirm to ${event?.id ? 'edit' : 'create'} this calendar event?`
    );
    if (confirm) {
      if (event.startDatetime && event.endDatetime && event.title) {
        if (event.startDatetime >= event.endDatetime) {
          alertContext.setAlert('Start date is greater than end date', 'danger');
        } else {
          const result = await fetchWithPost('/research/updateCalendar', event);
          if (result) {
            if (result.status === 400) {
              alertContext.setAlert(result.data.message, 'danger');
            } else {
              alertContext.setAlert('Successfully updated', 'success');
            }
          }
        }
      } else {
        alertContext.setAlert('Please fill in all the blank', 'danger');
      }
    }

    modalToggle();
  };

  const _remove = async () => {
    const confirm = await popUpContext.onOpen('Are you confirm to remove this calendar event?');
    if (confirm) {
      if (event.id) {
        const result = await fetchWithDelete(`/research/deleteCalendar/${event.id}`);
        if (result) {
          if (result.status === 400) {
            alertContext.setAlert(result.data.message, 'danger');
          } else {
            alertContext.setAlert('Successfully updated', 'success');
          }
        }
      }
    }
    modalToggle();
  };

  const [modal, setModal] = useState(false);
  const modalToggle = (v) => {
    if (v && v.id) {
      setEvent(v);
    } else {
      setEvent({});
      fetchApi();
    }
    setModal(!modal);
  };

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={`${rootPath}`}>Calendar</Link>
        </BreadcrumbItem>
      </Breadcrumb>

      <div className="mb-2 d-flex">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {colors.map((v) => (
            <div className="d-flex align-items-center mr-2">
              <div style={{ backgroundColor: v.color, width: 10, height: 10, marginRight: 8, borderRadius: 10 }}></div>
              <div>{v.label}</div>
            </div>
          ))}
        </div>

        <div style={{ width: '30%', textAlign: 'right' }}>
          <Button color="primary" onClick={modalToggle}>
            Create new event
          </Button>
        </div>
      </div>

      <Card>
        <CardBody>
          <Calendar
            localizer={localizer}
            events={calendar}
            startAccessor="startDatetime"
            endAccessor="endDatetime"
            style={{ height: 500 }}
            onSelectEvent={(event) => modalToggle(event)}
            onSelectSlot={(e) => console.log({ e })}
            eventPropGetter={(event, start, end, isSelected) => {
              return {
                style: {
                  backgroundColor: event.color,
                  color: 'white',
                },
              };
            }}
          />
        </CardBody>
      </Card>

      <Modal centered={true} isOpen={modal} toggle={modalToggle}>
        <ModalHeader toggle={modalToggle}>
          <b>{event?.id ? 'Edit Event' : 'Create new event'}</b>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className="mb-2">Title</Label>
            <Input
              placeholder="Title"
              value={event?.title}
              onChange={(v) => setEvent({ ...event, title: v.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">Start time</Label>
            <Datetime value={event?.startDatetime} onChange={(date) => setEvent({ ...event, startDatetime: date })} />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">End time</Label>
            <Datetime value={event?.endDatetime} onChange={(date) => setEvent({ ...event, endDatetime: date })} />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">Organizer</Label>
            <Input
              placeholder="Organizer"
              value={event?.organizer}
              onChange={(v) => setEvent({ ...event, organizer: v.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">Attendees</Label>
            <Input
              placeholder="Attendees"
              value={event?.attendees}
              onChange={(v) => setEvent({ ...event, attendees: v.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">Location</Label>
            <Input
              placeholder="Location"
              value={event?.location}
              onChange={(v) => setEvent({ ...event, location: v.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">Content</Label>
            <Input
              type="textarea"
              placeholder="Content"
              value={event?.content}
              onChange={(v) => setEvent({ ...event, content: v.target.value })}
            />
          </FormGroup>
          <FormGroup>
            <Label className="mb-2">Remarks</Label>
            <Input
              type="textarea"
              placeholder="Remarks"
              value={event?.remarks}
              onChange={(v) => setEvent({ ...event, remarks: v.target.value })}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_create}>
            {event?.id ? 'Edit' : 'Create'}
          </Button>
          {event?.id ? (
            <Button color="danger" onClick={_remove}>
              Remove
            </Button>
          ) : (
            <></>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
